import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarSupport from './SidebarSupport';
import cx from 'classnames';
import { connect } from 'react-redux';

const handleClick = (e, disabled) => {
  if (disabled) e.preventDefault();
};

const Sidebar = ({ type, userInfo }) => {
  const { pathname } = useLocation();
  const getPath = pathname.split('/')[1];

  const nav = [
    {
      link: `/${getPath}/dashboard`,
      icon: 'dashboard',
      name: 'Аналітика',
    },
    {
      link: `/${getPath}/order`,
      icon: 'fire',
      name: type === 'gas' ? 'Замовити газ' : 'Замовити електроенергію',
      // disabled: true
    },
    {
      link: `/${getPath}/new-invoice`,
      icon: 'new-invoice',
      name: 'Сформувати рахунок',
    },
    {
      link: `/${getPath}/documents`,
      icon: 'documents',
      name: 'Документи',
    },
    /*{
      link: `/${getPath}/team`,
      icon: 'union',
      name: 'Моя команда',
    }*/
  ];

  return (
    <aside className="sidebar">
      {/*<div className="sidebar__organization">{type=== 'gas' ? `ТОВ "Промпостач"` : `Товариство з обмеженою відповідальністю "ЛАВАНДА" `}</div>*/}

      <div className="sidebar__nav">
        {nav.map(({ link, icon, name, disabled = !userInfo.gasB2b }, index) => (
          <NavLink
            onClick={(e) => handleClick(e, disabled)}
            to={link}
            className={cx('sidebar-link', { disabled })}
            key={index}
          >
            <div className="sidebar-link__box">
              <img src={`/icons/${icon}.svg`} alt={icon} />
            </div>

            <span className="nav-unskew">{name}</span>
          </NavLink>
        ))}
      </div>

      <SidebarSupport />
    </aside>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};
export default connect(mapStateToProps)(Sidebar);
