import { middlewareUrl } from './config';
import { instanceFetch } from '../utils/instanceFetch';
import authHeader from '../utils/authHeader';

const ROUTES = {
  USER_INFO: new URL(`${middlewareUrl}/api/user/info`),
};

const getUserInfoService = () => {
  return instanceFetch.get(ROUTES.USER_INFO, authHeader()).then((response) => {
    return {
      accountsList: response.accountsList,
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      gasB2c: response.gasB2c,
      gasB2b: response.gasB2b,
      eB2b: response.eB2b,
    };
  });
};

export { getUserInfoService };
