import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SidebarSupport from './SidebarSupport';

const SidebarProfile = ({ userInfo }) => {
  const history = useHistory();

  if (!userInfo.gasB2c)
    return (
      <aside className="sidebar-profile-container">
        <div className="sidebar-profile" />

        <SidebarSupport />
      </aside>
    );

  return (
    <aside className="sidebar-profile-container">
      <div className="sidebar-profile">
        <button onClick={history.goBack} type="button" className="sidebar-back-to">
          <img src="/icons/arrow-back-black.svg" />
          Повернутися назад
        </button>
      </div>

      <SidebarSupport />
    </aside>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(SidebarProfile);
