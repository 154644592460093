import React, { useEffect, useState } from 'react';
import { instanceFetch } from '../../../utils/instanceFetch';
import { middlewareUrl } from '../../../services/config';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import authHeader from '../../../utils/authHeader';
import AnalyticsTable from './AnalyticsTable';
import Chart from '../../../common/Chart/Chart';
import moment from 'moment';

const AnalyticsItem = ({ item }) => {
  const { account } = item;
  const [currentFilterYear, setCurrentFilterYear] = useState('2022');
  const [accountData, setAccountData] = useState({
    calculations: {},
    info: {},
  });

  useEffect(() => {
    if (!account) return null;

    instanceFetch
      .get(
        `${middlewareUrl}/api/account/${account.accountNumber}/gas/calculations?created_at_range[start]=2015-01-01`,
        authHeader()
      )
      .then((calcResponse) =>
        instanceFetch
          .get(`${middlewareUrl}/api/account/${account.accountNumber}/info`, authHeader())
          .then((infoResponse) =>
            setAccountData({
              calculations: calcResponse,
              info: infoResponse,
            })
          )
      );
  }, [account]);

  if (!account) return null;

  if (isEmpty(accountData.info) && isEmpty(accountData.calculations))
    return (
      <div className="dashboard-b2c-item loading">
        <Spin />
      </div>
    );

  const { full_address } = accountData.info.data;

  const tableData = accountData.calculations.data;

  const months = [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ];

  const filteredDataForChartConfig = tableData
    .filter(({ created_at }) => {
      const year = moment(created_at).format('YYYY');

      return year === currentFilterYear;
    })
    .sort((prev, next) => {
      return moment(prev.created_at).format('M') - moment(next.created_at).format('M');
    })
    .reduce(
      (data, currentValue) => {
        const { payment, accruals, recalculation } = currentValue;
        const calcAccruals = accruals + recalculation;

        data.paymentData.push(payment.toFixed(2));
        data.accrualsData.push(calcAccruals.toFixed(2));
        return data;
      },
      { paymentData: [], accrualsData: [] }
    );

  const chartConfigData = [
    {
      label: 'Платежі',
      data: months.map((month, index) => filteredDataForChartConfig.paymentData[index]),
      backgroundColor: '#E87461',
      barPercentage: 0.7,
      categoryPercentage: 0.3,
      borderRadius: 10,
    },
    {
      label: 'Нарахування',
      data: months.map((month, index) => filteredDataForChartConfig.accrualsData[index]),
      backgroundColor: '#229C71',
      barPercentage: 0.7,
      categoryPercentage: 0.3,
      borderRadius: 10,
    },
  ];

  return (
    <div className="dashboard-b2c-item analytics-b2c">
      <div className="analytics-b2c-header">
        <div className="dashboard-b2c-item-header__title">
          Рахунок за адресою:
          <span className="dashboard-b2c-item-header__subtitle">{full_address}</span>
        </div>

        <div className="dashboard-b2c-item-header__text">
          Особовий рахунок {account.accountNumber}
        </div>
      </div>

      <div className="analytics-b2c-history-chart">
        <Chart
          tableData={tableData}
          chartConfigData={chartConfigData}
          chartConfigLabels={months}
          currentFilterYear={currentFilterYear}
          setCurrentFilterYear={setCurrentFilterYear}
        />
      </div>

      <AnalyticsTable tableData={tableData} currentFilterYear={currentFilterYear} />
    </div>
  );
};

export default AnalyticsItem;
