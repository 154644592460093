import React, { useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import TariffPlan from '../../components/TariffPlan/TariffPlan';
import InputText from '../../common/Inputs/InputText';
import FormDropdown from '../../common/FormDropdown/FormDropdown';
import TeamEdit from './TeamEdit';
import TeamAdd from './TeamAdd';
import TeamRemove from './TeamRemove';
import TeamMember from './TeamMember';

export const roles = ['Перегляд і завантаження', 'Редагування'];

const Team = () => {
  const [isModalEditVisible, setModalEditVisible] = useState(false);
  const [isModalAddVisible, setModalAddVisible] = useState(false);
  const [isModalRemoveVisible, setModalRemoveVisible] = useState(false);
  const [teamArray, setTeamArray] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  const teamArr = [
    {
      id: 1,
      name: 'Колобочкін Анатолій Олександрович',
      email: 'kolobo4kin_buhgalter2021@gmail.com',
      phone: '+380984042233',
      position: 'Бухгалтер',
    },
    {
      id: 2,
      name: 'Газова Антоніна Михайлівна',
      email: 'antonina.secretar.2021@gmail.com',
      phone: '+380984041213',
      position: 'Секретар',
    },
  ];

  useEffect(() => {
    setTeamArray(teamArr);
  }, [teamArr]);

  const handleEditModal = (member) => {
    setModalEditVisible(!isModalEditVisible);
    setSelectedMember(member);
  };
  const handleAddModal = (member) => {
    setModalAddVisible(!isModalAddVisible);
    setSelectedMember(member);
  };
  const handleRemoveModal = (member) => {
    setModalRemoveVisible(!isModalRemoveVisible);
    setSelectedMember(member);
  };

  return (
    <div className="my-team">
      <div className="box-shadow box-shadow-padding item-rounded">
        <div className="box-row">
          <div className="box-row-text">
            <div className="box-shadow-title">Моя команда</div>
            <div className="box-shadow-sub-title">
              Додавайте необхідні контакти й створюйте власну команду для спільного керування
              рахунками.
            </div>
          </div>
          <Button type="outline-secondary" onClick={handleAddModal}>
            <span>
              <img src="/icons/plus-black.svg" />
            </span>
            <span>Додати контакт</span>
          </Button>
        </div>
      </div>

      {teamArray.map((item) => (
        <TeamMember
          key={item.id}
          member={item}
          handleRemoveModal={handleRemoveModal}
          handleEditModal={handleEditModal}
        />
      ))}

      <TeamAdd
        isModalAddVisible={isModalAddVisible}
        handleAddModal={handleAddModal}
        member={selectedMember}
      />
      <TeamEdit
        isModalEditVisible={isModalEditVisible}
        handleEditModal={handleEditModal}
        member={selectedMember}
      />
      <TeamRemove
        isModalRemoveVisible={isModalRemoveVisible}
        handleRemoveModal={handleRemoveModal}
        member={selectedMember}
      />
    </div>
  );
};

export default Team;
