import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter, useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import DashboardGas from './pages/DashboardGas/DashboardGas';
import DashboardGasB2B from './pages/Gas-B2C/Dashboard/DashboardGas';
import DocumentsGas from './pages/DocumentsGas/DocumentsGas';
import OrderGas from './pages/OrderGas/OrderGas';
import DocumentsGasB2C from './pages/Gas-B2C/Documents/DocumentsGas';
import SignIn from './pages/Registration/SignIn';
import SignUp from './pages/Registration/SignUp';
import ForgotPassword from './pages/Registration/ForgotPassword/ForgotPassword';
import Profile from './pages/Profile/Profile';
import MainPage from './pages/MainPage';
import AccountManagement from './pages/AccountManagement/AccountManagement';
import Team from './pages/Team/Team';
import { technicalWork } from './services/config';
import {
  ROUTE_HOME,
  ROUTE_UNAUTHORIZED,
  ROUTES_UNAUTHORIZAED_ALLOWED,
  ROUTE_MODERATION,
  ROUTE_GAS_B2C,
  ROUTE_ENERGY,
  ROUTE_GAS_B2C_DASHBOARD,
  ROUTE_GAS_B2B_DASHBOARD,
  ROUTE_ENERGIYA_B2B,
  ROUTE_GAS_B2B,
  ROUTE_DASHBOARD,
  ROUTE_DOCUMENTS,
  ROUTE_DOCUMENTS_CONTRACTS,
  ROUTE_ENERGY_DOCUMENTS,
  ROUTE_ENERGY_DOCUMENTS_CONTRACTS,
  ROUTE_TECHNICAL_WORK,
  REDIRECT_GAS_DASHBOARD,
  REDIRECT_GAS_ORDER,
  REDIRECT_GAS_INVOICE,
  REDIRECT_GAS_CONTRACTS,
  REDIRECT_GAS_SUBCONTRACTS,
  REDIRECT_GAS_INVOICES,
  REDIRECT_GAS_ACTS,
  REDIRECT_ENERGY_DASHBOARD,
  REDIRECT_ENERGY_DOCUMENTS,
  REDIRECT_ENERGY_ORDER,
  REDIRECT_ENERGY_INVOICE,
  REDIRECT_ENERGY_CONTRACTS,
  REDIRECT_ENERGY_SUBCONTRACTS,
  REDIRECT_ENERGY_INVOICES,
  REDIRECT_ENERGY_ACTS,
} from './constants';
import TeamCreatePassword from './pages/Team/TeamCreatePassword/TeamCreatePassword';
import SignInOtp from './pages/Registration/SigninOtp/SignInOtp';
import Analytics from './pages/Gas-B2C/Analytics/Analytics';
import Requests from './pages/Gas-B2C/Requests/Requests';
import GasB2B from './pages/DashboardGas/GasB2B';
import BotB2B_IIT from './pages/Bot/BotB2B_IIT';
import NewInvoiceGasB2B from './pages/NewInvoiceGasB2B/NewInvoiceGasB2B';
import Address from './pages/Gas-B2C/Address/Address';
import DocumentsSignature from './pages/DocumentsGas/DocumentsSignature';
import MainPageB2b from './pages/MainPageB2b';
import SignUpB2b from './pages/Registration/SignUpB2b';
import DocumentsGasContracts from './pages/DocumentsGas/DocumentsGasContracts';
import DocumentsGasSubcontracts from './pages/DocumentsGas/DocumentsGasSubcontracts';
import DocumentsGasActs from './pages/DocumentsGas/DocumentsGasActs';
import DocumentsGasInvoices from './pages/DocumentsGas/DocumentsGasInvoices';
import TemporaryUnavailable from './pages/TemporaryUnavailable/TemporaryUnavailable';

const mainRoutes = [
  {
    path: '/',
    component: MainPage,
    exact: true,
  },
  {
    path: '/b2b',
    component: MainPageB2b,
    exact: true,
  },
  {
    path: '/gas-b2b',
    exact: true,
    component: GasB2B,
    routes: [
      {
        path: '/dashboard',
        component: DashboardGas,
        exact: false,
      },
      {
        path: '/order',
        component: OrderGas,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsGasContracts,
        exact: true,
      },
      {
        path: '/documents/contracts',
        component: DocumentsGasContracts,
        exact: true,
      },
      {
        path: '/documents/subcontracts',
        component: DocumentsGasSubcontracts,
        exact: true,
      },
      {
        path: '/documents/acts',
        component: DocumentsGasActs,
        exact: true,
      },
      {
        path: '/documents/invoices',
        component: DocumentsGasInvoices,
        exact: true,
      },
      {
        path: '/account-management',
        component: AccountManagement,
        exact: false,
      },
      {
        path: '/new-invoice',
        component: NewInvoiceGasB2B,
        exact: false,
      },
      {
        path: '/team',
        component: Team,
        exact: false,
      },
    ],
  },
  {
    path: '/energiya',
    exact: true,
    component: GasB2B,
    routes: [
      {
        path: '/dashboard',
        component: DashboardGas,
        exact: false,
      },
      {
        path: '/order',
        component: OrderGas,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsGasContracts,
        exact: true,
      },
      {
        path: '/documents/contracts',
        component: DocumentsGasContracts,
        exact: true,
      },
      {
        path: '/documents/subcontracts',
        component: DocumentsGasSubcontracts,
        exact: true,
      },
      {
        path: '/documents/acts',
        component: DocumentsGasActs,
        exact: true,
      },
      {
        path: '/documents/invoices',
        component: DocumentsGasInvoices,
        exact: true,
      },
      {
        path: '/account-management',
        component: AccountManagement,
        exact: false,
      },
      {
        path: '/new-invoice',
        component: NewInvoiceGasB2B,
        exact: false,
      },
      {
        path: '/team',
        component: Team,
        exact: false,
      },
    ],
  },
  {
    path: '/sign-in',
    component: SignIn,
    exact: true,
    routes: [
      {
        path: '/team',
        component: TeamCreatePassword,
        exact: false,
      },
    ],
  },
  {
    path: '/sign-up',
    component: SignUp,
    exact: true,
  },
  {
    path: '/b2b/sign-up',
    component: SignUpB2b,
    exact: true,
  },
  {
    path: '/sign-in-otp',
    component: SignInOtp,
    exact: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: false,
  },
  {
    path: '/profile',
    component: Profile,
    exact: false,
  },
  {
    path: '/gas-b2c',
    component: DashboardGasB2B,
    exact: true,
    routes: [
      {
        path: '/dashboard',
        component: DashboardGasB2B,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsGasB2C,
        exact: false,
      },
      {
        path: '/analytics',
        component: Analytics,
        exact: false,
      },
      {
        path: '/address',
        component: Address,
        exact: false,
      },
      {
        path: '/requests',
        component: Requests,
        exact: false,
      },
    ],
  },
  {
    path: '/b2b-bot',
    component: BotB2B_IIT,
    exact: true,
    routes: [
      {
        path: '/auth',
        component: BotB2B_IIT,
        exact: false,
      },
      {
        path: '/documents',
        component: DocumentsSignature,
        exact: false,
      },
    ],
  },
  {
    path: '/technical-work',
    component: TemporaryUnavailable,
    exact: false,
  },
];

const RenderRoutes = ({ auth, location, userInfo }) => {
  const history = useHistory();

  useEffect(() => {
    if (technicalWork) {
      console.log(technicalWork);
      history.push('/technical-work');
    }
  }, [technicalWork]);
  const renderAuthorizedComponent = (Component) => () => {
    const { gasB2b, eB2b, gasB2c } = userInfo;
    const { token } = auth;

    const activeGasB2c = !isNull(gasB2c) && userInfo.gasB2c;
    const activeGasB2b = !isNull(gasB2b) && userInfo.gasB2b;
    const activeEnergiyaB2b = !isNull(eB2b) && userInfo.eB2b;

    const homeRoute = location.pathname === ROUTE_HOME;
    const signinRoute = location.pathname === ROUTE_UNAUTHORIZED;
    const b2cRoute = location.pathname === ROUTE_GAS_B2C;
    const b2bRoute = location.pathname === ROUTE_GAS_B2B;
    const energyRoute = location.pathname === ROUTE_ENERGY;
    const dashboardB2CRoute = location.pathname === ROUTE_GAS_B2C_DASHBOARD;
    const dashboardB2BRoute = location.pathname === ROUTE_GAS_B2B_DASHBOARD;
    const energiyaB2bRoute = location.pathname === ROUTE_ENERGIYA_B2B;
    const gasB2bRoute = location.pathname === ROUTE_GAS_B2B_DASHBOARD;
    const gasB2bDocuments = location.pathname === ROUTE_DOCUMENTS;
    const energiyaB2bDocuments = location.pathname === ROUTE_ENERGY_DOCUMENTS;

    const newPath = 'https://b2b.gaszbut.com.ua';
    const gasB2bDashboard = location.pathname === REDIRECT_GAS_DASHBOARD
    const gasB2bOrder = location.pathname === REDIRECT_GAS_ORDER
    const gasB2bInvoice = location.pathname === REDIRECT_GAS_INVOICE
    const gasB2bContracts = location.pathname === REDIRECT_GAS_CONTRACTS
    const gasB2bSubContracts = location.pathname === REDIRECT_GAS_SUBCONTRACTS
    const gasB2bInvoices = location.pathname === REDIRECT_GAS_INVOICES
    const gasB2bActs = location.pathname === REDIRECT_GAS_ACTS

    const energyDashboard = location.pathname === REDIRECT_ENERGY_DASHBOARD
    const energyDoc = location.pathname === REDIRECT_ENERGY_DOCUMENTS
    const energyOrder = location.pathname === REDIRECT_ENERGY_ORDER
    const energyInvoice = location.pathname === REDIRECT_ENERGY_INVOICE
    const energyContracts = location.pathname === REDIRECT_ENERGY_CONTRACTS
    const energySubContracts = location.pathname === REDIRECT_ENERGY_SUBCONTRACTS
    const energyInvoices = location.pathname === REDIRECT_ENERGY_INVOICES
    const energyActs = location.pathname === REDIRECT_ENERGY_ACTS


    if ((signinRoute || homeRoute || b2bRoute) && token && activeGasB2b) {
      window.location.href = `${newPath}${REDIRECT_GAS_DASHBOARD}`
    }

    if (token && activeGasB2b && gasB2bDashboard) {
      window.location.href = `${newPath}${REDIRECT_GAS_DASHBOARD}`
    }

    if (token && activeGasB2b && gasB2bOrder) {
      window.location.href = `${newPath}${REDIRECT_GAS_ORDER}`
    }

    if (token && activeGasB2b && gasB2bInvoice) {
      window.location.href = `${newPath}${REDIRECT_GAS_INVOICE}`
    }

    if (token && activeGasB2b && gasB2bContracts) {
      window.location.href = `${newPath}${REDIRECT_GAS_CONTRACTS}`
    }

    if (token && activeGasB2b && gasB2bSubContracts) {
      window.location.href = `${newPath}${REDIRECT_GAS_SUBCONTRACTS}`
    }

    if (token && activeGasB2b && gasB2bInvoices) {
      window.location.href = `${newPath}${REDIRECT_GAS_INVOICES}`
    }

    if (token && activeGasB2b && gasB2bActs) {
      window.location.href = `${newPath}${REDIRECT_GAS_ACTS}`
    }

    if (token && energyRoute && activeEnergiyaB2b) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_DASHBOARD}`
    }

    if (token && activeEnergiyaB2b && energyDashboard) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_DASHBOARD}`
    }

    if (token && activeEnergiyaB2b && energyOrder) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_ORDER}`
    }

    if (token && activeEnergiyaB2b && energyInvoice) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_INVOICE}`
    }

    if (token && activeEnergiyaB2b && energyContracts) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_CONTRACTS}`
    }

    if (token && activeEnergiyaB2b && energySubContracts) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_SUBCONTRACTS}`
    }

    if (token && activeEnergiyaB2b && energyInvoices) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_INVOICES}`
    }

    if (token && activeEnergiyaB2b && energyActs) {
      window.location.href = `${newPath}${REDIRECT_ENERGY_ACTS}`
    }


    if ((signinRoute || homeRoute || dashboardB2BRoute) && token && !activeGasB2c) {
      return <Redirect to={ROUTE_GAS_B2C} />;
    }

    if ((signinRoute || homeRoute || dashboardB2CRoute) && token && !activeGasB2c) {
      return <Redirect to={ROUTE_GAS_B2C} />;
    }

    if ((signinRoute || homeRoute || b2bRoute) && token && activeGasB2b) {
      return <Redirect to={ROUTE_GAS_B2B_DASHBOARD} />;
    }

    if ((signinRoute || homeRoute || b2cRoute) && token && activeGasB2c) {
      return <Redirect to={ROUTE_GAS_B2C_DASHBOARD} />;
    }

    if (gasB2bDocuments) {
      return <Redirect to={ROUTE_DOCUMENTS_CONTRACTS} />;
    }

    if (energiyaB2bDocuments) {
      return <Redirect to={ROUTE_ENERGY_DOCUMENTS_CONTRACTS} />;
    }

    return !token && ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname) === false ? (
      <Redirect to={ROUTE_HOME} />
    ) : (
      <Component />
    );
  };

  return mainRoutes.map((router) => {
    if (router.routes) {
      const Child = router.component;
      const { path, exact } = router;
      return (
        <Switch key={router.path}>
          <Route {...{ path, exact }} render={renderAuthorizedComponent(Child)} />
          {router.routes.map((childrenRouter) => {
            const Child = childrenRouter.component;
            return (
              <Route
                key={router.path + childrenRouter.path}
                path={router.path + childrenRouter.path}
                exact={childrenRouter.exact}
                render={renderAuthorizedComponent(Child)}
              />
            );
          })}
        </Switch>
      );
    }

    const Child = router.component;
    return (
      <Route
        key={router.path}
        exact={router.exact}
        path={router.path}
        render={renderAuthorizedComponent(Child)}
      />
    );
  });
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    auth: application.auth,
    userInfo: application.userInfo,
  };
};

export default memo(withRouter(connect(mapStateToProps, null)(RenderRoutes)));
