import React, { useEffect, useState } from 'react';
import Button from '../../../common/Button/Button';
import { Link, withRouter } from 'react-router-dom';
import { Form } from 'antd';
import renderErrors from '../../../helper/functions';
import Checkbox from '../../../common/Checkbox/Checkbox';
import InputText from '../../../common/Inputs/InputText';
import { register } from '../../../store/actions/register';
import { connect } from 'react-redux';
import { EMAIL_REGEXP } from '../../../constants';

const ContentStep2 = ({ registerAction, regions, handleStep, setSendVia, setLoginValue }) => {
  const [form] = Form.useForm();

  const [active, setActive] = useState(false);
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = (values) => {
    setLoginValue(values.login);
    EMAIL_REGEXP.test(values.login) ? setSendVia('1') : setSendVia('2');
    registerAction({
      ...values,
      send_via: EMAIL_REGEXP.test(values.login) ? '1' : '2',
    })
      .then(() => {
        handleStep((p) => p + 1);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content step-1">
      <img className="" src="/icons/confirm1.svg" />
      <span className="reg-content-title-black">
        Після підтвердження номеру або email потрібно буде зазначити номер особового рахунку та
        створити пароль
      </span>
      <Form
        name="registration"
        onFinish={handlerSubmit}
        form={form}
        initialValues={{
          region_id: regions.length > 0 ? regions[0].regionId : null,
        }}
      >
        <InputText
          name="login"
          prefix={<img src="/icons/user-form.svg" />}
          tooltipText="Номер телефону у форматі +380"
          labelText="Email або номер телефону, вказані в договорі"
        />

        <Button typeHtml="submit" type="primary" text="Зареєструватись" />

        <Checkbox
          checkboxValue={active}
          checkboxHandler={() => setActive(!active)}
          text="Даю свою згоду на збір та оброблення наданих персональних даних"
          name="rules_confirmed"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Поле має бути заповнено')),
            },
          ]}
        />

        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { referenceBooks } = store.application;
  return {
    regions: referenceBooks.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerAction: (payload) => dispatch(register(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentStep2));
