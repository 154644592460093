import React from 'react';
import Button from '../../common/Button/Button';
import StatusLabel from '../../common/StatusLabel/StatusLabel';
import { Tooltip } from 'antd';

const TariffPlanGasB2C = () => {
  return (
    <div className="tariff-plan tariff-b2c">
      <div className="tariff-plan-header">
        <div className="tariff-plan-header__title">Тарифні плани</div>
      </div>

      <div className="tariff-plan__content">
        <div className="tariff-plan-table recommended-tariff">
          <div className="tariff-plan-table-header">
            <div className="tariff-plan-table-header__title empty" />

            <div className="tariff-plan-table-header__title">
              Місячний <StatusLabel text="Активний" color="gray" />
            </div>
            <div className="tariff-plan-table-header__title">
              Річний{' '}
              <Tooltip placement="top" title="Tooltip">
                <img src="/icons/help.svg" alt="help-icon" />
              </Tooltip>
              <StatusLabel text="Рекомендований" color="green" />
            </div>
            <div className="tariff-plan-table-header__title">На зиму</div>
            <div className="tariff-plan-table-header__title">Рівномірний</div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text price-title">Ціна за м³</div>
            </div>
            <div className="tariff-plan-table__col price">
              <div className="tariff-plan-table__value">7,99 грн.</div>
              <div className="tariff-plan-table__text month">у листопаді</div>
              <div className="tariff-plan-table__text year">змінюється щомісяця</div>
            </div>
            <div className="tariff-plan-table__col price">
              <div className="tariff-plan-table__value">7,99 грн.</div>
              <div className="tariff-plan-table__text month">щомісяця</div>
              <div className="tariff-plan-table__text year">фіксована протягом року</div>
            </div>

            <div className="tariff-plan-table__col price">
              <div className="tariff-plan-table__value">6,99 грн.</div>
              <div className="tariff-plan-table__text month">щомісяця</div>
              <div className="tariff-plan-table__text year">
                за умови передплати до кінця жовтня
              </div>
            </div>
            <div className="tariff-plan-table__col price">
              <div className="tariff-plan-table__value">7,99 грн.</div>
              <div className="tariff-plan-table__text month">щомісяця</div>
              <div className="tariff-plan-table__text year">
                за умови підключення у червні, потім дорожче{' '}
                <Tooltip placement="top" title="Tooltip">
                  <img src="/icons/help.svg" alt="help-icon" />
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Коли можна підключити</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">У будь-який момент</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">У будь-який момент</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">До 31 серпня</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">До 31 серпня</div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Термін дії</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Необмежений</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">12 місяців</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">До травня 2022</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">До травня 2022</div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Умови підключення</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Без обмежень</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Без обмежень</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Відсутність боргів</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Відсутність боргів</div>
            </div>
          </div>

          <div className="tariff-plan-table__inner">
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">Терміни оплати</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">до 25 числа наступного місяця</div>
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">до 25 числа наступного місяця</div>
              <Button type="primary" text="Залишити заявку" />
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">
                100% передплата або 50% одразу, а потім частинами до 25 жовтня
              </div>
              <Button type="outline-primary" text="Залишити заявку" />
            </div>
            <div className="tariff-plan-table__col">
              <div className="tariff-plan-table__text">до 25 числа наступного місяця</div>
              <Button type="outline-primary" text="Залишити заявку" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TariffPlanGasB2C;
