import React, { useEffect, useState } from 'react';
import Button from '../../../common/Button/Button';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import renderErrors from '../../../helper/functions';
import { connect, useSelector } from 'react-redux';
import PasswordAndConfirm from '../PasswordAndConfirm';
import { passwordUpdate } from '../../../services/auth';

const ContentStep4B2b = ({ history, regions }) => {
  const [form] = Form.useForm();
  const token = useSelector(({ application }) => application.auth.token);

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const handlerSubmit = (values) => {
    passwordUpdate(values, { authorization: `Bearer ${token}` })
      .then(() => {
        window.location.replace(`${window.location.origin}/gas-b2c/dashboard`);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div className="registration-content">
      <span className="reg-content-title-blue">Створіть пароль</span>
      <Form
        name="registration"
        onFinish={handlerSubmit}
        form={form}
        initialValues={{
          region_id: regions.length > 0 ? regions[0].regionId : null,
        }}
      >
        <PasswordAndConfirm />

        <Button typeHtml="submit" type="primary" text="Зберегти і ввійти" />

        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { referenceBooks } = store.application;
  return {
    regions: referenceBooks.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerAction: (payload) => dispatch(passwordUpdate(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentStep4B2b));
