import React from 'react';
import { Form, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import AddProfileWidget from '../../../components/AddProfileWidget/AddProfileWidget';

const DashboardGasEmptyProfile = () => {
  return (
    <div className="empty-profile">
      <div className="empty-profile__top">
        <img className="" src="/icons/confirm1.svg" />
        <div className="empty-profile__title">Ваш обліковий запис ще не активний</div>
        <div className="empty-profile__subtitle">
          Ви можете додати існуючий обліковий рахунок або створити нову заяву на приєднання{' '}
        </div>
      </div>
      <AddProfileWidget
        widgetHeader="Додати  обліковий рахунок"
        widgetSubheader="Вкажіть номер вашого рахунку та дайте відповідь на секретне запитання"
        widgetButton="Додати адресу"
      />
      <div className="empty-profile__links">
        <div className="empty-profile__tooltip">
          Ще не маєте облікового рахунку?
          <Tooltip placement="top" title="Ще не маєте облікового рахунку?">
            <img src="/icons/help.svg" alt="help-icon" />
          </Tooltip>
        </div>
        <Link to="/forgot-password">
          <button type="button" className="underline-link">
            Подати заявку на приєднання
          </button>
        </Link>
      </div>
    </div>
  );
};

export default DashboardGasEmptyProfile;
