import { middlewareUrl } from './config';
import { instanceFetch } from '../utils/instanceFetch';
import authHeader from '../utils/authHeader';

const ROUTES = {
  PROFILE: new URL(`${middlewareUrl}/api/user/profile`),
  CHANGE_LOGIN: new URL(`${middlewareUrl}/api/user/change-login`),
};

const profileGetService = () => {
  return instanceFetch.get(ROUTES.PROFILE, authHeader()).then(
    (response) => {
      return {
        firstname: response.first_name,
        middlename: response.middle_name,
        lastname: response.last_name,
        phone: response.phone,
        email: response.email,
      };
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const profileUserUpdateService = (payload) => {
  return instanceFetch.patch(ROUTES.PROFILE, payload, authHeader()).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const profileUserChangeLoginService = (payload) => {
  return instanceFetch.post(ROUTES.CHANGE_LOGIN, payload, authHeader()).then(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

export { profileGetService, profileUserUpdateService, profileUserChangeLoginService };
