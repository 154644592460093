import React from 'react';
import Switch from '../../common/Switch/Switch';

const NotificationTab = () => {
  return (
    <div className="notification-tab">
      <span className="notification-tab-title">
        Оберіть, яку актуальну інформацію ви б хотіли отримувати на свій email:
      </span>
      <div className="notification-tab-checkbox-list">
        <Switch text="Важливі сповіщення" />
        <Switch text="Новини компанії" />
        <Switch text="Документи" />
      </div>
    </div>
  );
};

export default NotificationTab;
