import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isNull } from 'lodash';
import { Modal } from 'antd';
import DashboardGasItem from './DashboardGasItem';
import DashboardGasEmptyProfile from './DashboardGasEmptyProfile';
import DashboardGasAll from './DashboardGasAll';
import AddProfileWidget from '../../../components/AddProfileWidget/AddProfileWidget';
import { middlewareUrl } from '../../../services/config';

const DashboardGas = ({ userInfo }) => {
  const [successModal, setSuccessModal] = useState();

  const ROUTES = {
    ACCOUNT_STORE: new URL(`${middlewareUrl}/api/account/store`),
  };

  useEffect(() => {
    if (window.location.href.includes('success')) return setSuccessModal(true);
  }, []);

  if (isNull(userInfo.accountsList)) return null;

  if (!userInfo.accountsList.length) return <DashboardGasEmptyProfile />;

  return (
    <div className="dashboard-b2c">
      {/*<DashboardGasAll />*/}

      {userInfo.accountsList.map((item, index) => (
        <DashboardGasItem key={index} item={item} />
      ))}

      {userInfo.gasB2c && (
        <div className="dashboard-add-account">
          <AddProfileWidget
            widgetHeader="Мої адреси та доступ до них"
            widgetSubheader="Додавайте інші облікові рахунки, за які ви хотіли б сплачувати через особистий кабінет"
            widgetButton="Додати адресу"
          />
        </div>
      )}

      <Modal
        className="success-modal"
        visible={successModal}
        footer={null}
        onCancel={() => setSuccessModal(false)}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Ваш платіж успішно виконано!</div>
          <div className="text-grey-dark-normal-f16-l28">
            Очікуйте відображення даних у вашому кабінеті найближчим часом
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(DashboardGas);
