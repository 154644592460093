import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { base64toBlob } from '../../../helper/functions';
import { Form, InputNumber, Modal, Spin, Table, Tooltip } from 'antd';
import { middlewareUrl } from '../../../services/config';
import { instanceFetch } from '../../../utils/instanceFetch';
import authHeader from '../../../utils/authHeader';
import ModalWrapper from '../../../common/Modal/ModalWrapper';
import Button from '../../../common/Button/Button';
import TariffPlanGasB2C from '../../../components/TariffPlan/TariffPlanGasB2C';

const columns = [
  {
    title: 'Попередній місяць',
    dataIndex: 'previousMonth',
    className: 'previousMonth',
  },
  {
    title: 'Поточний місяць',
    dataIndex: 'currentMonth',
    className: 'currentMonth',
  },
  {
    title: 'Наступний місяць',
    dataIndex: 'nextMonth',
    className: 'nextMonth',
  },
];

const data = [];
for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    previousMonth: '-',
    currentMonth: '-',
    nextMonth: '-',
  });
}

const DashboardGasItem = ({ item }) => {
  const { account } = item;
  const [accountInfo, setAccountInfo] = useState(null);
  const [isVisibleDashboard, setVisibleDashboard] = useState(false);
  const [isModalTariffVisible, setModalTariffVisible] = useState(false);

  useEffect(() => {
    if (!account) return null;

    instanceFetch
      .get(`${middlewareUrl}/api/account/${account.accountNumber}/info`, authHeader())
      .then((response) => setAccountInfo(response));
  }, [account]);

  if (!account) return null;

  if (!accountInfo)
    return (
      <div className="dashboard-b2c-item loading">
        <Spin />
      </div>
    );

  const handleModalTariff = () => {
    setModalTariffVisible(!isModalTariffVisible);
  };

  return (
    <div className="dashboard-b2c-item">
      <div className="dashboard-b2c-item__inner">
        <DashboardGasItemHeader accountInfo={accountInfo} accountNumber={account.accountNumber} />
        <DashboardGasItemContent accountInfo={accountInfo} handleModalTariff={handleModalTariff} />
        <DashboardGasItemFooter accountInfo={accountInfo} accountNumber={account.accountNumber} />
      </div>

      <button
        type="button"
        onClick={() => setVisibleDashboard(!isVisibleDashboard)}
        className={`dashboard-contract__btn ${isVisibleDashboard ? 'active' : ''}`}
      >
        <img src="/icons/arrow-right.svg" alt="arrow-icon" />
        Динаміка зміни ціни
      </button>

      {isVisibleDashboard && (
        <div className="dashboard-contract-data">
          <Table className="dynamic-table" bordered columns={columns} dataSource={data} />
        </div>
      )}

      {isModalTariffVisible && (
        <ModalWrapper
          className="tariff-modal"
          isModalVisible={isModalTariffVisible}
          handleClick={handleModalTariff}
          modalWidth={1140}
          closeIcon={<img src="/icons/modal-close.svg" />}
        >
          <TariffPlanGasB2C />
        </ModalWrapper>
      )}

      <ModalWrapper
        className="success-modal"
        isModalVisible={isModalTariffVisible}
        handleClick={handleModalTariff}
        modalWidth={770}
      >
        <div className="success-modal__title">Наразі немає доступних Тарифних планів для зміни</div>
      </ModalWrapper>
    </div>
  );
};

const DashboardGasItemHeader = ({ accountInfo, accountNumber }) => {
  return (
    <div className="dashboard-b2c-item-header">
      <div className="dashboard-b2c-item-header__title">
        Рахунок за адресою:
        <span className="dashboard-b2c-item-header__subtitle">{accountInfo.data.full_address}</span>
      </div>

      <div className="dashboard-b2c-item-header__text">Особовий рахунок {accountNumber}</div>
    </div>
  );
};

const prepareSaldo = (saldo, fieldName) => {
  if (fieldName === 'balance') return saldo.toFixed(2) * -1;
  if (fieldName === 'payment') return saldo > 0 ? saldo : 0;
};

const DashboardGasItemContent = ({ accountInfo, handleModalTariff }) => {
  const {
    tariff = {
      tariff_price: '7.99',
      tariff_name: 'ТВІЙ ГАЗ РІВНОМІРНИЙ ПЛАТІЖ',
    },
  } = accountInfo;

  const consumption = parseInt(accountInfo.data.saldo / tariff.tariff_price);

  const balanceValueClassName = cx('dashboard-b2c-item-content-data__text', 'bold', {
    'green': +accountInfo.data.saldo * -1 > 0,
    'text-red': +accountInfo.data.saldo * -1 < 0,
  });

  return (
    <div className="dashboard-b2c-item-content">
      <div className="dashboard-b2c-item-content__left">
        <div className="dashboard-b2c-item-content-data">
          {tariff.tariff_price !== null && (
            <div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">Ціна за газ</div>
              <div className="dashboard-b2c-item-content-data__text bold">
                {tariff.tariff_price} грн/м³
              </div>
            </div>
          )}

          <div className="dashboard-b2c-item-content-data__row">
            <div className="dashboard-b2c-item-content-data__text">
              Рахунок за попередній місяць:
            </div>
            <div className="dashboard-b2c-item-content-data__text bold">
              {accountInfo.data.saldo_last_month} грн.
            </div>
          </div>

          <div className="dashboard-b2c-item-content-data__row">
            <div className="dashboard-b2c-item-content-data__text">Баланс:</div>
            <div className={balanceValueClassName}>
              {prepareSaldo(+accountInfo.data.saldo, 'balance')} грн.
            </div>
          </div>

          <div className="dashboard-b2c-item-content-data__row">
            <div className="dashboard-b2c-item-content-data__text">До сплати:</div>
            <div className="dashboard-b2c-item-content-data__text bold">
              {prepareSaldo(+accountInfo.data.saldo, 'payment')} грн.
            </div>
          </div>
        </div>
      </div>
      {tariff.tariff_name !== null && (
        <div className="dashboard-b2c-item-content__right">
          <div className="dashboard-b2c-item-content-data__row">
            <div className="dashboard-b2c-item-content-data__text">Тариф</div>
            <div className="dashboard-b2c-item-content-data__text bold">{`“${tariff.tariff_name}”`}</div>
            <Tooltip
              className="tooltip"
              placement="top"
              title={`Ціна за м³ - ${tariff.tariff_price} грн`}
            >
              <img src="/icons/help.svg" alt="help-icon" />
            </Tooltip>
          </div>
          <button type="button" className="underline-link" onClick={handleModalTariff}>
            Змінити тариф
          </button>
        </div>
      )}
    </div>
  );
};

const DashboardGasItemFooter = ({ accountInfo, accountNumber }) => {
  const [isRenderErrorModal, setRenderErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [isDisabledButton, setDisabledButton] = useState(false);
  const [isLoading, setLoading] = useState({
    pay: false,
    check: false,
  });

  const currentDate = moment().format('YYYY.MM.DD');

  const ROUTES = {
    INVOICE: new URL(`${middlewareUrl}/api/kosht/generate-invoice`),
    PDF: new URL(`${middlewareUrl}/api/account/${accountNumber}/invoice?date=${currentDate}`),
  };

  const handleSubmit = (values) => {
    const amountPrepare = `${values.amount * 100}`;
    setLoading({
      ...isLoading,
      pay: true,
    });

    instanceFetch
      .post(
        ROUTES.INVOICE,
        {
          accountNumber: accountNumber,
          amount: amountPrepare,
          redirectUrl: window.location.href,
        },
        authHeader()
      )
      .then((response) => {
        setLoading({
          ...isLoading,
          pay: false,
        });
        // window.open(`${response.url}?success`)
      })
      .catch((e) => {
        setLoading({
          ...isLoading,
          pay: false,
        });
        setErrorModalText(e.message);
        setRenderErrorModal(true);
      });
  };

  useEffect(() => {
    if (accountInfo.data.saldo <= 0) return setDisabledButton(true);
    return setDisabledButton(false);
  }, []);

  const handleFormChange = (e) => {
    if (e.amount <= 0) return setDisabledButton(true);
    return setDisabledButton(false);
  };

  const getPdf = () => {
    setLoading({
      ...isLoading,
      check: true,
    });

    instanceFetch
      .get(ROUTES.PDF, authHeader())
      .then((response) => {
        const url = URL.createObjectURL(
          base64toBlob(`data:application/pdf;base64,${response.invoice_pdf}`)
        );
        setLoading({
          ...isLoading,
          check: false,
        });

        window.open(url, '_blank');
      })
      .catch(() => {
        setLoading({
          ...isLoading,
          check: false,
        });
        setRenderErrorModal(true);
      });
  };

  const initialValues = {
    amount: prepareSaldo(+accountInfo.data.saldo, 'payment'),
  };

  return (
    <div>
      {accountInfo.data.saldo > 0 && (
        <div className="amount-to-pay">
          <div className="amount-to-pay__title">Сума до оплати:</div>

          <Form
            onValuesChange={handleFormChange}
            initialValues={initialValues}
            onFinish={handleSubmit}
          >
            <div className="amount-to-pay__input">
              <Form.Item name="amount" className="field">
                <InputNumber className="field__input" parser={(value) => value.replace(',', '.')} />
              </Form.Item>

              <div className="currency">грн.</div>
            </div>

            <Button
              disabled={isDisabledButton}
              typeHtml="submit"
              type="primary primary-green"
              text="Сплатити"
            >
              {isLoading.pay && (
                <div className="btn__loading">
                  <Spin />
                </div>
              )}
            </Button>
          </Form>

          {accountInfo.tariff.tariff_name !== 'ТВІЙ ГАЗ РІВНОМІРНИЙ ПЛАТІЖ' && (
            <Button
              onClick={() => getPdf()}
              className="dashboard-download"
              type="outline-secondary"
            >
              <img src="/icons/plus-black.svg" className="btn__icon-left" alt="plus-icon" />
              <span>Завантажити рахунок</span>
              {isLoading.check && (
                <div className="btn__loading">
                  <Spin />
                </div>
              )}
            </Button>
          )}

          <Modal
            className="success-modal"
            visible={isRenderErrorModal}
            footer={null}
            onCancel={() => setRenderErrorModal(false)}
          >
            <div className="success-modal__wrapper">
              <div className="success-modal__title">
                <div>Помилка!</div>
                <div>{errorModalText}</div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default DashboardGasItem;
