import React, { useEffect, useState } from 'react';
import OrderGasFilter from './OrderGasFilter';
import OrderGasCreate from './OrderGasCreate';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';

const OrderGas = () => {
  const [resetForm, setResetForm] = useState(false);
  const [orderFilter, setOrderFilter] = useState({});
  const [contractsArr, setContractsArr] = useState([]);

  const filtersLength = Object.keys(orderFilter).length;
  let accountEdrpou;

  useEffect(() => {
    instanceFetch
      .get(`${middlewareUrl}/api/user/info`, authHeader())
      .then((response) => {
        accountEdrpou = response.accountCompanies[0].edrpou;
      })
      .then(() => {
        instanceFetch
          .get(
            `${middlewareUrl}/api/gas-b2b/consumer/by-edrpou?edrpou=${accountEdrpou}`,
            authHeader()
          )
          .then((response) => {
            if (response.contracts !== undefined) {
              setContractsArr(response.contracts.map((contract) => 'Договір №' + contract.id));
            }
          });
      });
  }, []);

  return (
    <div>
      <OrderGasFilter
        setResetForm={setResetForm}
        resetForm={resetForm}
        setOrderFilter={setOrderFilter}
        orderFilter={orderFilter}
        contractsArr={contractsArr}
      />
      {filtersLength === 4 && (
        <OrderGasCreate orderFilter={orderFilter} setResetForm={setResetForm} />
      )}
    </div>
  );
};

export default OrderGas;
