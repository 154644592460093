import React, { useState, useMemo } from 'react';
import TabLabel from '../../common/Tab/TabLabel';
import MyProfileTab from './MyProfileTab';
import ChangePasswordTab from './ChangePasswordTab';
import NotificationTab from './NotificationTab';

const arrProfileTab = [
  {
    label: 'Мій профіль',
    content: () => <MyProfileTab />,
    disabled: false,
  },
  {
    label: 'Змінити пароль',
    content: () => <ChangePasswordTab />,
    disabled: false,
  },
  {
    label: 'Сповіщення',
    content: () => <NotificationTab />,
    disabled: false,
  },
];

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);

  const Content = useMemo(() => {
    return arrProfileTab[activeTab].content;
  }, [activeTab, arrProfileTab]);

  const handleClick = (e, disabled) => {
    if (disabled) e.preventDefault();
  };

  return (
    <div className="profile box-shadow">
      <div className="tab-label-list">
        {arrProfileTab.map(({ label, disabled }, index) => (
          <TabLabel
            disabled={disabled}
            key={index}
            label={label}
            onClick={(e) => {
              handleClick(e, disabled);
              setActiveTab(index);
            }}
            active={activeTab === index}
          />
        ))}
      </div>
      <div className="profile-tab-content">
        <Content />
      </div>
    </div>
  );
};

export default Profile;
