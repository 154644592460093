import React, { useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import 'moment/locale/uk';
import { evenPayment } from '../../../constants';

const columns = [
  {
    title: 'Місяць',
    dataIndex: 'created_at',
    className: 'month',
  },
  {
    title: 'Тариф',
    dataIndex: 'tariff',
    className: 'tariff',
  },
  {
    title: (
      <>
        <span className="table-header-title">Баланс (на початок періоду)</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'saldo_month_begin',
    className: 'saldo_begin',
  },
  {
    title: (
      <>
        <span className="table-header-title">Нарахування</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'accruals',
    className: 'accruals',
  },
  {
    title: (
      <>
        <span className="table-header-title">Перерахунки</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'recalculation',
    className: 'recalculation',
  },
  {
    title: (
      <>
        <span className="table-header-title">Платежі</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'payment',
    className: 'payment',
  },
  {
    title: (
      <>
        <span className="table-header-title">Пільги</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'total_exemption',
    className: 'total_exemption',
  },
  {
    title: (
      <>
        <span className="table-header-title">Пеня</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'fine',
    className: 'fine',
  },
  {
    title: (
      <>
        <span className="table-header-title">Баланс (на кінець періоду)</span>
        <span className="table-header-sub-title">грн</span>
      </>
    ),
    dataIndex: 'saldo_month_end',
    className: 'saldo_end',
  },
];

const renderTariff = (tariff_name, price) => {
  if (tariff_name === evenPayment) return `${tariff_name}* / ${price} грн`;

  return `${tariff_name.length ? `${tariff_name} / ${price} грн` : '-'}`;
};

const AnalyticsTable = ({ tableData, currentFilterYear }) => {
  const [isVisibleDashboard, setVisibleDashboard] = useState(false);

  const prepareTableData = tableData
    .filter(({ created_at }) => {
      const preparCreatedAt = moment(created_at).format('YYYY');

      return preparCreatedAt === currentFilterYear;
    })
    .map(
      ({
        created_at,
        saldo_month_begin,
        saldo_month_end,
        payment,
        total_exemption,
        accruals,
        recalculation,
        fine,
        tariff_name,
        price,
      }) => ({
        created_at: moment(created_at).locale('ua').format('MMMM'),
        saldo_month_begin: saldo_month_begin,
        saldo_month_end: saldo_month_end,
        payment: payment,
        total_exemption: total_exemption,
        accruals: accruals,
        recalculation: recalculation,
        fine: fine,
        tariff: renderTariff(tariff_name, price),
      })
    );

  const isEvenPayment = prepareTableData.some(({ tariff_name }) => tariff_name === evenPayment);

  return (
    <div className="analytics-b2c-table">
      <button
        type="button"
        onClick={() => setVisibleDashboard(!isVisibleDashboard)}
        className={`analytics-b2c-btn ${isVisibleDashboard ? 'active' : ''}`}
      >
        <img src="/icons/arrow-right.svg" alt="arrow-icon" />
        Показати баланс по місяцях
      </button>

      {isVisibleDashboard && (
        <div className="custom-ant-table">
          <Table bordered columns={columns} dataSource={prepareTableData} pagination={false} />

          {isEvenPayment && (
            <div className="analytics-b2c-table__even-payment">
              *Розрахунки відображені на підставі даних Оператора ГРМ, визначених за договором
              розподілу природного газу.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AnalyticsTable;
