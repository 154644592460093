import React, { useMemo, useState } from 'react';
import DocumentsReceipt from './DocumentsReceipt';
import DocumentsContract from './DocumentsContract';
import TabLabel from '../../../common/Tab/TabLabel';

const arrDocumentsTab = [
  {
    label: 'Рахунки',
    content: () => <DocumentsReceipt />,
  },
  {
    label: 'Інші документи',
    content: () => <DocumentsContract />,
  },
];

const DocumentsGasB2C = () => {
  const [activeTab, setActiveTab] = useState(0);

  const Content = useMemo(() => {
    return arrDocumentsTab[activeTab].content;
  }, [activeTab, arrDocumentsTab]);

  return (
    <div className="documents documents-b2c">
      <div className="tab-label-list">
        {arrDocumentsTab.map(({ label }, index) => (
          <TabLabel
            key={index}
            label={label}
            onClick={() => setActiveTab(index)}
            active={activeTab === index}
          />
        ))}
      </div>
      <div className="documents-tab-content box-shadow">
        <Content />
      </div>
    </div>
  );
};

export default DocumentsGasB2C;
