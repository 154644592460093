import React from 'react';
import FormDropdown from '../../../common/FormDropdown/FormDropdown';
import { Tooltip } from 'antd';

const downloadReceiptBtn = (
  // TODO: Mayby replace to text iconed btn
  <div className="download-receipt">
    <Tooltip placement="bottom" title="Скачати">
      <img src="/icons/download.svg" />
    </Tooltip>
  </div>
);

const contracts = [];

const addressArr = ['Адреса 0017', 'Адреса 0018', 'Адреса 0019', 'Адреса 0011', 'Адреса 009'];
const statusArr = ['Статус 0017', 'Статус 0018', 'Статус 0019', 'Статус 0011', 'Статус 009'];
const periodArr = ['2021', '2020', '2019', '2018', '2017', '2016'];

const DocumentsContract = () => {
  return (
    <div className="documents-contract">
      <form className="form-selected-filter">
        <FormDropdown defaultValue="Адреса" options={addressArr} />
        <FormDropdown defaultValue="Статус оплати" options={statusArr} />
        <FormDropdown defaultValue="Період" options={periodArr} />

        <button type="button" className="underline-link">
          Очистити фільтр
        </button>
      </form>

      <div className="info-box-list">
        {contracts.map(({ name, time }, index) => (
          <div className="info-blue-box" key={index}>
            <div className="contract-item-info">
              <span className="contract-item-title">{name}</span>
              <span className="contract-item-sub-title">{time}</span>
            </div>
            {downloadReceiptBtn}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentsContract;
