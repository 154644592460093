import React, { useEffect, useState } from 'react';
import { instanceFetch } from '../../../utils/instanceFetch';
import { middlewareUrl } from '../../../services/config';
import authHeader from '../../../utils/authHeader';
import { Modal, Spin, Table } from 'antd';

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'date',
  },
  {
    title: 'Електронна пошта',
    dataIndex: 'email',
    className: 'email',
  },
  {
    title: 'ПІБ',
    dataIndex: 'name',
    className: 'name',
  },
  {
    title: 'Доступ',
    dataIndex: 'access',
    className: 'access',
  },
];

const data = [];
for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    date: '-',
    email: '-',
    name: '-',
    access: '-',
  });
}

const AddressItem = ({ item }) => {
  const { account } = item;
  const [accountInfo, setAccountInfo] = useState(null);
  const [isVisibleDashboard, setVisibleDashboard] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  // console.log(accountInfo)

  const deleteAccountAddress = () => {
    instanceFetch
      .patch(
        `${middlewareUrl}/api/account/detach`,
        {
          account_number: account.accountNumber,
        },
        authHeader()
      )
      .then(() => setDeleteAccountModal(false))
      .then(() =>
        setTimeout(() => {
          window.location.reload();
        }, 500)
      );
  };

  useEffect(() => {
    if (!account) return null;

    instanceFetch
      .get(`${middlewareUrl}/api/account/${account.accountNumber}/info`, authHeader())
      .then((response) => setAccountInfo(response));
  }, [account]);

  if (!account) return null;

  if (!accountInfo)
    return (
      <div className="dashboard-b2c-item loading">
        <Spin />
      </div>
    );

  return (
    <div className="dashboard-b2c-item dashboard-b2c-item--address">
      <div className="dashboard-b2c-item__inner">
        <div className="dashboard-b2c-item-header">
          <div className="dashboard-b2c-item-header__title">
            Адреса:
            <span className="dashboard-b2c-item-header__subtitle">
              {accountInfo.data.full_address}
            </span>
          </div>

          <div className="dashboard-b2c-item-header__text">
            Особовий рахунок {account.accountNumber}
            <a
              onClick={() => setDeleteAccountModal(true)}
              className="underline-link underline-link--red"
            >
              Видалити адресу
            </a>
          </div>
        </div>
        <div className="dashboard-b2c-item-content">
          <div className="dashboard-b2c-item-content__left">
            <div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">Площа:</div>
              <div className="dashboard-b2c-item-content-data__text bold">
                {accountInfo.data.square} м<sup>2</sup>
              </div>
            </div>
            <div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">Кількість мешканців:</div>
              <div className="dashboard-b2c-item-content-data__text bold">
                {accountInfo.data.people_count}
              </div>
            </div>
            <div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">
                Наявність пільги (субсидії):
              </div>
              <div className="dashboard-b2c-item-content-data__text bold">Немає</div>
            </div>
          </div>
          <div className="dashboard-b2c-item-content__right">
            {/*<div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">Постачальник:</div>
              <div className="dashboard-b2c-item-content-data__text bold">{accountInfo.bank_account.companyName}</div>
            </div>*/}
            <div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">EIC-код:</div>
              <div className="dashboard-b2c-item-content-data__text bold">
                {accountInfo.data.eic_code}
              </div>
            </div>
          </div>
          <div className="dashboard-b2c-item-content__full">
            <div className="dashboard-b2c-item-content-data__row">
              <div className="dashboard-b2c-item-content-data__text">Призначення:</div>
              <div className="dashboard-b2c-item-content-data__text bold">
                {accountInfo.data.groupconsumption}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="delete-account-modal"
        visible={deleteAccountModal}
        footer={null}
        onCancel={() => setDeleteAccountModal(false)}
      >
        <div className="delete-account-modal__wrapper">
          <div className="delete-account-modal__title">Видалити адресу?</div>
          <div className="delete-account-modal__address">
            Адреса: {accountInfo.data.full_address}
          </div>
          <div className="delete-account-modal__subtitle">
            Увага! Після видалення адреси з персонального кабінету, доступ до рахунків по цій адресі
            буде неможливий.
          </div>
          <div className="delete-account-modal__buttons">
            <button onClick={() => setDeleteAccountModal(false)} className="btn primary">
              <span>Не видаляти</span>
            </button>
            <button onClick={deleteAccountAddress} className="btn outline-primary">
              <span>Видалити</span>
            </button>
          </div>
        </div>
      </Modal>

      <button
        type="button"
        onClick={() => setVisibleDashboard(!isVisibleDashboard)}
        className={`dashboard-contract__btn ${isVisibleDashboard ? 'active' : ''}`}
      >
        <img src="/icons/arrow-right.svg" alt="arrow-icon" />
        Члени сім’ї
      </button>

      {isVisibleDashboard && (
        <div className="dashboard-contract-data">
          <Table className="dynamic-table" bordered columns={columns} dataSource={data} />
        </div>
      )}
    </div>
  );
};

export default AddressItem;
