import React from 'react';
import { connect } from 'react-redux';
import { isNull } from 'lodash';
import AnalyticsItem from './AnalyticsItem';

const Analytics = ({ userInfo }) => {
  const { accountsList } = userInfo;

  if (isNull(accountsList)) return null;

  if (!accountsList.length) return <div>Пусто</div>;

  return (
    <div className="analytics-b2c">
      {accountsList.map((item, index) => (
        <AnalyticsItem item={item} key={index} />
      ))}
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(Analytics);
