import React from 'react';

const arr = [
  {
    icon: 'icon5',
    name: 'Зручно оплачувати рахунки за газ — без черг та не гаючи часу.',
  },
  {
    icon: 'icon3',
    name: 'Додавати членів родини чи орендарів для оплати комунальних послуг за потрібними адресами.',
  },
  {
    icon: 'icon2',
    name: 'Додавати кілька адрес і оплачувати всі рахунки у два кліки;',
  },
  {
    icon: 'icon1',
    name: 'Змінювати обраний тариф для ще більшої вигоди;',
  },
];

const SidebarStep1 = () => {
  return (
    <div className="registration-sidebar registration-sidebar__main">
      <h2 className="registration-sidebar-h2">ТВІЙ ГАЗЗБУТ</h2>
      <span className="reg-sidebar-sub-title">В особистому кабінеті ви можете:</span>
      <div className="registration-sidebar-list">
        {arr.map(({ icon, name }) => (
          <div className="registration-sidebar-item" key={name}>
            <div className="sidebar-list-image-container">
              <img src={`/icons/${icon}.svg`} />
            </div>
            <span>{name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarStep1;
