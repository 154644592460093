import React from 'react';
import { Popover, Table } from 'antd';
import FormDropdown from '../../../common/FormDropdown/FormDropdown';
import StatusLabel from '../../../common/StatusLabel/StatusLabel';
import Button from '../../../common/Button/Button';

const columns = [
  {
    title: '№ рахунку',
    dataIndex: 'accountNumber',
    className: 'number',
  },
  {
    title: 'Адреса',
    dataIndex: 'address',
    className: 'address',
  },
  {
    title: 'Сума до оплати',
    dataIndex: 'paymentAmount',
    className: 'payment-amount',
  },
  {
    title: 'Статус оплати',
    dataIndex: 'paymentStatus',
    className: 'payment-status',
  },
  {
    title: 'Період',
    dataIndex: 'period',
    className: 'period',
  },
  {
    title: (
      <div className="label-with-icon">
        Квитанції{' '}
        <Popover
          placement="bottom"
          content="Можливість переглянути та завантажити квитанції
    з’являється якщо рахунок оплачувався через платіжну систему Kosht "
          trigger="hover"
        >
          <img src="/icons/help.svg" alt="help-icon" />
        </Popover>
      </div>
    ),
    dataIndex: 'receipt',
    className: 'receipt',
  },
];

const data = [];
for (let i = 0; i < 44; i++) {
  data.push({
    key: i,
    accountNumber: `0123456-${i}`,
    address: `м. Кривий Ріг, вул. Першотравнева, буд. 4, кв. 98`,
    paymentAmount: `501,06 грн.`,
    paymentStatus: <StatusLabel icon text="Оплачено 21 серпня" color="gray-blue" />,
    period: 'Січень, 2021',
    receipt: <Button type="primary" text="Сплатити рахунок" />,
    // receipt: downloadReceiptBtn,
  });
}

const addressArr = ['Адреса 0017', 'Адреса 0018', 'Адреса 0019', 'Адреса 0011', 'Адреса 009'];
const statusArr = ['Статус 0017', 'Статус 0018', 'Статус 0019', 'Статус 0011', 'Статус 009'];
const periodArr = ['2021', '2020', '2019', '2018', '2017', '2016'];

const DocumentsReceipt = () => {
  return (
    <>
      <form className="form-selected-filter">
        <FormDropdown defaultValue="Адреса" options={addressArr} />
        <FormDropdown defaultValue="Статус оплати" options={statusArr} />
        <FormDropdown defaultValue="Період" options={periodArr} />

        <button type="button" className="underline-link">
          Очистити фільтр
        </button>
      </form>
      <div className="custom-ant-table">
        <Table className="documents-receipt-b2c" bordered columns={columns} dataSource={data} />
      </div>
    </>
  );
};

export default DocumentsReceipt;
