import React, { useState, useEffect, useRef } from 'react';
import Button from '../../common/Button/Button';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import { instanceFetch } from '../../utils/instanceFetch';
import { middlewareUrl } from '../../services/config';
import authHeader from '../../utils/authHeader';
import { decode, encode } from 'base64-arraybuffer';
import { Form, Spin } from 'antd';
import InputText from '../../common/Inputs/InputText';

const DocumentsSignature = ({ setSelectedItem, selectedItemData, fetchDocs }) => {
  const [isVisiblePopup, setVisiblePopup] = useState(false);
  const [isBackPopup, setBackPopup] = useState(false);
  const [isDeleteDocPopup, setDeleteDocPopup] = useState(false);
  const [isDeleteDocReasonPopup, setDeleteDocReasonPopup] = useState(false);
  const [btnSignType, setBtnSignType] = useState(0);
  const [signError, setSignError] = useState('');
  const [signDoc, setSignDoc] = useState(false);
  const [signedDoc, setSignedDoc] = useState('');
  const [documentStamped, setDocumentStamped] = useState(false);
  const [documentSigned, setDocumentSigned] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const randomString = Math.random().toString(36).substring(7);

  const signingDocument = !signDoc ? selectedItemData.itemFileData : signedDoc;

  const handleModalVisible = () => {
    setVisiblePopup(!isVisiblePopup);
  };

  const handleBackModalVisible = () => {
    setBackPopup(!isBackPopup);
  };

  const handleDeleteDocModalVisible = () => {
    setDeleteDocPopup(!isDeleteDocPopup);
  };

  const handleDeleteDocReasonModalVisible = () => {
    setDeleteDocReasonPopup(!isDeleteDocReasonPopup);
  };

  const submitDocument = () => {
    instanceFetch
      .patch(
        `${middlewareUrl}/api/b2b/electronic-documents/${selectedItemData.itemId}`,
        {
          document_base64_content: signedDoc,
          document_signed: 1,
          document_stamped: btnSignType === 2 ? 1 : 0,
        },
        authHeader()
      )
      .then(() => {
        fetchDocs();
        setSelectedItem(false);
      });
  };

  const deleteDocument = (value) => {
    setLoading(true);
    instanceFetch
      .patch(
        `${middlewareUrl}/api/b2b/electronic-documents/${selectedItemData.itemId}/cancel/`,
        {
          comment: value.reason,
        },
        authHeader()
      )
      .then(() => {
        fetchDocs();
      })
      .then(() => {
        setSelectedItem(false);
        setDeleteDocPopup(!isDeleteDocPopup);
        setDeleteDocReasonPopup(!isDeleteDocReasonPopup);
      })
      .catch(() => {
        fetchDocs();
        setSelectedItem(false);
        setDeleteDocPopup(!isDeleteDocPopup);
        setDeleteDocReasonPopup(!isDeleteDocReasonPopup);
      });
  };

  useEffect(() => {
    if (isVisiblePopup) {
      let uint = new Uint8Array(decode(signingDocument));
      const asBase64String = true;
      const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;

      const onConfirmKSPOperation = (event) => {
        var node = '';
        node += '<a href="' + event.url + '">';
        node +=
          '<img src="data:image/bmp;base64,' +
          event.qrCode +
          '" style="padding: 10px; background: white;">';
        node += '</a>';

        document.getElementById('qr-code-block').innerHTML = node;
        document.getElementById('qr-code-block').style.display = 'block';
      };

      let euSign = new EndUser(
        'sign-widget-parent' /* Ідентифікатор батківського елементу */,
        'sign-widget' /* Ідентифікатор елементу iframe */,
        'https://eu.iit.com.ua/sign-widget/v20220527/' /* URI для завантаження iframe */,
        EndUser.FormType.ReadPKey /* Тип форми iframe */
      );

      setTimeout(() => {
        signDocument();
      }, 10000);

      const signDocument = () => {
        euSign
          .ReadPrivateKey()
          .then((res) => {
            return euSign.AddEventListener(
              EndUser.EventType.ConfirmKSPOperation,
              onConfirmKSPOperation
            );
          })
          .then(() => {
            euSign
              .SignData(uint, false, asBase64String, signAlgo, null)
              .then((sign) => {
                setSignedDoc(sign);
                setSignDoc(true);
                btnSignType === 1 && setDocumentSigned(true);
                btnSignType === 2 && setDocumentStamped(true);
                handleModalVisible();
              })
              .catch((e) => {
                document.getElementById('qr-code-block').style.display = 'none';
                console.log(
                  'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
                );
                setSignError(
                  'Виникла помилка при підписі даних. ' + 'Опис помилки: ' + (e.message || e)
                );
              });
          })
          .catch(function (e) {
            console.log(
              'Виникла помилка при зчитуванні ос. ключа. ' + 'Опис помилки: ' + (e.message || e)
            );
          });
      };
    }
  }, [isVisiblePopup]);

  return (
    <>
      <div className="signature-buttons">
        <button
          className="underline-link underline-link--back"
          onClick={() => {
            documentStamped || documentSigned ? setBackPopup(true) : setSelectedItem(false);
          }}
        >
          <img src="/icons/arrow-left.svg" />
          <span>Назад до документів</span>
        </button>
        {!documentSigned && selectedItemData.itemFileStatus === 2 && (
          <button
            className="underline-link underline-link--red"
            onClick={handleDeleteDocModalVisible}
          >
            <img src="/icons/triangle-red.svg" />
            <span>Відхилити документ</span>
          </button>
        )}
      </div>
      <div className="documents-signature">
        <div className="documents-signature-header">
          <div className="documents-signature-header__title">№{selectedItemData.itemTitle}</div>

          <div className="documents-signature-header__buttons">
            <div className="documents-signature-header__buttons-sign">
              {selectedItemData.itemFileStatus === 3 ||
              selectedItemData.itemFileStatus === 4 ||
              signDoc ? (
                <div className="button-label-complete button-label-complete--first">
                  <img src="/icons/checkmark_circle.svg" />
                  <span>Накладено КЕП</span>
                </div>
              ) : (
                <Button
                  type="btn outline-primary"
                  className={signDoc ? 'button-sign button-sign--disabled' : 'button-sign'}
                  typeHtml="submit"
                  onClick={() => {
                    setVisiblePopup(!isVisiblePopup);
                    setBtnSignType(1);
                  }}
                >
                  <span>
                    <img src="/icons/plus.svg" />
                  </span>
                  <span>Підписати через КЕП</span>
                </Button>
              )}
              {selectedItemData.itemFileStatus === 4 || documentStamped ? (
                <div className="button-label-complete">
                  <img src="/icons/checkmark_circle.svg" />
                  <span>Накладено печатку</span>
                </div>
              ) : (
                <Button
                  className={
                    selectedItemData.itemFileStatus === 2 && !signDoc
                      ? 'button-sign button-sign--disabled'
                      : 'button-sign'
                  }
                  type="outline-primary"
                  typeHtml="submit"
                  onClick={() => {
                    setVisiblePopup(!isVisiblePopup);
                    setBtnSignType(2);
                  }}
                >
                  <span>
                    <img src="/icons/plus.svg" />
                  </span>
                  <span>Накласти печатку</span>
                </Button>
              )}
            </div>
            <div className="documents-signature-header__buttons-submit">
              <Button
                className={!signDoc ? 'button-sign button-sign--disabled' : 'button-sign'}
                type="primary"
                typeHtml="submit"
                text="Відправити"
                onClick={submitDocument}
              />
            </div>
          </div>
        </div>

        <div className="documents-signature__iframe">
          <iframe
            src={selectedItemData.itemFileUrl + `?cache=${randomString}`}
            // src={selectedItemData.itemFileMeta + selectedItemData.itemFileData}
            width="100%"
            height="532"
            frameBorder="0"
          />
        </div>
        {isVisiblePopup && (
          <ModalWrapper
            className="add-iit-form"
            isModalVisible={isVisiblePopup}
            handleClick={handleModalVisible}
            closeIcon={<img src="/icons/modal-close-black.svg" />}
          >
            <div id="sign-widget-parent" className="iit-widget"></div>
            <div id="qr-code-block"></div>
            <textarea id="textAreaData"></textarea>
            <div className="sign-modal-error">{signError}</div>
          </ModalWrapper>
        )}
        {isBackPopup && (
          <ModalWrapper
            className="documentsBackModal"
            isModalVisible={isBackPopup}
            handleClick={handleBackModalVisible}
            closeIcon={<img src="/icons/modal-close-black.svg" />}
          >
            <div className="backModal">
              <div className="backModal__title">Ви впевнені?</div>
              <div className="backModal__text">
                Якщо ви не повернетесь до розділу документів, то ваш підпис не буде збережено
              </div>
              <div className="backModal__buttons">
                <Button type="primary" text="Залишитись" onClick={handleBackModalVisible} />
                <Button
                  type="outline-primary"
                  text="До документів"
                  onClick={() => setSelectedItem(false)}
                />
              </div>
            </div>
          </ModalWrapper>
        )}
        {isDeleteDocPopup && (
          <ModalWrapper
            className="documentsBackModal"
            isModalVisible={isDeleteDocPopup}
            handleClick={handleDeleteDocModalVisible}
            closeIcon={<img src="/icons/modal-close-black.svg" />}
          >
            <div className="backModal">
              <div className="backModal__title">Ви впевнені?</div>
              <div className="backModal__text">
                Це незворотня дія. Після відхилення документи його буде видалено із системи
              </div>
              <div className="backModal__buttons">
                <Button type="primary" text="Повернутись" onClick={handleDeleteDocModalVisible} />
                <Button
                  type="outline-primary outline-primary-red"
                  text="Відхилити"
                  onClick={handleDeleteDocReasonModalVisible}
                />
              </div>
            </div>
          </ModalWrapper>
        )}
        {isDeleteDocReasonPopup && (
          <ModalWrapper
            className="deleteDocReasonModal"
            isModalVisible={isDeleteDocReasonPopup}
            handleClick={() => {
              handleDeleteDocReasonModalVisible();
              handleDeleteDocModalVisible();
            }}
            closeIcon={<img src="/icons/modal-close-black.svg" />}
          >
            <div className="backModal">
              <div className="backModal__title">Залиште ваш коментар</div>
              <div className="backModal__text">
                Це допоможе нам швидше внести корективи у разі виникнення помилки
              </div>
              <Form onFinish={deleteDocument}>
                <InputText name="reason" labelText="Коментар" />
                <div className="backModal__buttons">
                  <Button
                    type="primary"
                    text="Повернутись"
                    onClick={() => {
                      handleDeleteDocReasonModalVisible();
                      handleDeleteDocModalVisible();
                    }}
                  />
                  <Button
                    typeHtml="submit"
                    type="outline-primary outline-primary-red"
                    text="Відміна"
                  >
                    {isLoading && (
                      <div className="btn__loading">
                        <Spin />
                      </div>
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </ModalWrapper>
        )}
      </div>
    </>
  );
};

export default DocumentsSignature;
