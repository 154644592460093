import { middlewareUrl } from './config';
import { instanceFetch } from '../utils/instanceFetch';
import authHeader from '../utils/authHeader';
const ROUTES = {
  SEND_OTP: new URL(`${middlewareUrl}/api/user/registration/send-otp`),
  REGISTER_BY_OTP: new URL(`${middlewareUrl}/api/user/registration/register-by-otp`),
  PASSWORD_UPDATE: new URL(`${middlewareUrl}/api/user/password/update`),
  REGISTER_ACCOUNT_ADDRESS: new URL(`${middlewareUrl}/api/account/store`),
};

const registerService = (payload) => {
  return instanceFetch.post(ROUTES.SEND_OTP, payload).then(
    (response) => {
      return {
        response,
      };
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const registerServiceOtp = (payload) => {
  return instanceFetch.post(ROUTES.REGISTER_BY_OTP, payload).then(
    (response) => {
      return {
        ...response,
        token: payload.access_token,
        user: payload.login,
      };
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

const registerServiceAccountAddress = (payload) => {
  return instanceFetch.post(ROUTES.REGISTER_ACCOUNT_ADDRESS, payload, authHeader()).then(
    (response) => {
      return {
        response,
      };
    },
    (error) => {
      return Promise.reject({
        ...error,
      });
    }
  );
};

export { registerService, registerServiceOtp, registerServiceAccountAddress };
