import React, { useEffect, useState } from 'react';
import InputText from '../../common/Inputs/InputText';
import Button from '../../common/Button/Button';
import { Form } from 'antd';
import { passwordUpdate } from '../../services/auth';
import renderErrors from '../../helper/functions';
import { useSelector } from 'react-redux';
import ModalWrapper from '../../common/Modal/ModalWrapper';

const ChangePasswordTab = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const [form] = Form.useForm();
  const token = useSelector(({ application }) => application.auth.token);

  const handleSubmitForm = (values) => {
    passwordUpdate(values, { authorization: `Bearer ${token}` })
      .then(() => {
        form.resetFields();
        setSuccessModal(true);
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <Form form={form} onFinish={handleSubmitForm} className="change-password-form">
      <InputText
        password
        name="password"
        placeholder="Введіть новий пароль"
        labelText="Введіть новий пароль"
        prefix={<img src="/icons/lock.svg" />}
        rules={[
          {
            min: 8,
            message: 'Мінімум 8 символів',
            validateTrigger: 'onSubmit',
          },
          {
            required: true,
            message: 'Допускаються лише символи та цифри',
            pattern: new RegExp(/^[a-zA-Z0-9]*$/),
            validateTrigger: 'onSubmit',
          },
        ]}
      />

      <InputText
        password
        name="password_confirmation"
        placeholder="Введіть новий пароль повторно"
        labelText="Введіть новий пароль повторно"
        prefix={<img src="/icons/lock.svg" />}
        rules={[
          {
            min: 8,
            message: 'Мінімум 8 символів',
            validateTrigger: 'onSubmit',
          },
          {
            required: true,
            message: 'Допускаються лише символи та цифри',
            pattern: new RegExp(/^[a-zA-Z0-9]*$/),
            validateTrigger: 'onSubmit',
          },
        ]}
      />

      <Button type="primary" typeHtml="submit" text="Зберегти" />

      {error.message && <div className="error">{error.message}</div>}

      <ModalWrapper
        className="success-modal"
        isModalVisible={successModal}
        handleClick={() => setSuccessModal(false)}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Ваш пароль успішно змінений!</div>
        </div>
      </ModalWrapper>
    </Form>
  );
};

export default ChangePasswordTab;
