import React, { useEffect, useState } from 'react';
import Button from '../../common/Button/Button';
import { withRouter } from 'react-router-dom';
import { Form, InputNumber, Input, Select, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { getCompanies } from '../../store/actions/companies';
import { middlewareUrl } from '../../services/config';
import { instanceFetch } from '../../utils/instanceFetch';
import authHeader from '../../utils/authHeader';
import { getUserInfo } from '../../store/actions/userInfo';
import { ROUTE_GAS_B2C_DASHBOARD } from '../../constants';
import FieldLabel from '../../common/Inputs/FieldLabel';
import renderErrors from '../../helper/functions';
import InputText from '../../common/Inputs/InputText';

const ProfileModerationMain = ({ setActiveTab }) => {
  return (
    <div className="profile__block profile_moderation">
      <div className="confirm-step">
        <div className="confirm-step-icon">
          <img src="/icons/confirm1.svg" />
        </div>

        <div className="confirm-step-title">Ваш аккаунт на модерації</div>

        <div className="confirm-step-sub-title">
          Для самостійної активації вашого особистого кабінети ви можете підключити особовий рахунок
          або підключити власний бізнес
        </div>

        <div className="confirm-step__buttons">
          <Button onClick={() => setActiveTab(1)} type="primary" text="Додати особовий рахунок" />
          <Button
            onClick={(e) => e.preventDefault()}
            type="primary"
            disabled
            text="Приєднати бізнес"
          />
        </div>
      </div>
    </div>
  );
};
const ProfileModerationAccount = ({
  history,
  companies,
  getCompaniesService,
  getUserInfoAction,
  setActiveTab,
}) => {
  const [form] = Form.useForm();

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const ROUTES = {
    ACCOUNT_STORE: new URL(`${middlewareUrl}/api/account/store`),
  };

  useEffect(() => {
    getCompaniesService();
  }, []);

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  const FieldLabel = ({ labelText, tooltipText }) => (
    <>
      {labelText}
      <Tooltip className="tooltip" placement="top" title={tooltipText}>
        <img src="/icons/help.svg" alt="help-icon" />
      </Tooltip>
    </>
  );

  const handlerSubmit = ({ last_payment_sum, accountNumber }) => {
    instanceFetch
      .post(
        ROUTES.ACCOUNT_STORE,
        {
          accountNumber: `${accountNumber}`,
          last_payment_sum: `${last_payment_sum}`,
        },
        authHeader()
      )
      .then(() => {
        getUserInfoAction().then(() => history.push(ROUTE_GAS_B2C_DASHBOARD));
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  return (
    <div className="profile-moderation">
      <div className="profile-moderation__title">Додати свій особовий рахунок</div>
      <Form form={form} className="profile-moderation-form" onFinish={handlerSubmit}>
        <div className="field">
          <Form.Item
            label={
              <FieldLabel
                labelText="Оберіть Компанію постачальника Газу"
                tooltipText="Оберіть компанію із якою у вас укладений договір на постачання газу"
              />
            }
          >
            <Select
              placeholder="Оберіть компанію"
              suffixIcon={<img src="/icons/arrow-select.svg" alt="arrow-icon" />}
              rules={[{ required: true, message: 'Необхідно обрати компанію' }]}
            >
              {companies?.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="field">
          <Form.Item
            name="accountNumber"
            className="field"
            label={
              <FieldLabel
                labelText="Номер особового рахунку"
                tooltipText="Зазначте номер вашого особового рахунку"
              />
            }
          >
            <InputText
              type="number"
              placeholder="Введіть номер рахунку"
              className="field__input"
              rules={[{ required: true, message: 'Необхідно зазначити номер особового рахунку' }]}
            />
          </Form.Item>
        </div>

        <div className="field">
          <Form.Item
            name="last_payment_sum"
            label={
              <FieldLabel
                labelText="Останній платіж"
                tooltipText="Вкажіть суму останнього платежу. Якщо сума з копійками, зазначте її у форматі XXX.XX"
              />
            }
          >
            <InputNumber
              placeholder="Введіть останній платіж"
              className="field__input"
              parser={(value) => value.replace(',', '.')}
              rules={[{ required: true, message: 'Необхідно вказати останню сумму поповнення' }]}
            />
          </Form.Item>
        </div>

        <div className="profile-moderation-form__buttons">
          <Button onClick={() => setActiveTab(0)} type="primary" text="Назад" />
          <Button type="primary" typeHtml="submit" text="Додати" />
        </div>

        {error.message && <div className="error">{error.message}</div>}
      </Form>
    </div>
  );
};

const ProfileModeration = ({
  history,
  userInfo,
  companies,
  getCompaniesService,
  getUserInfoAction,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const renderTab = {
    0: <ProfileModerationMain setActiveTab={setActiveTab} />,
    1: (
      <ProfileModerationAccount
        history={history}
        userInfo={userInfo}
        companies={companies}
        getCompaniesService={getCompaniesService}
        getUserInfoAction={getUserInfoAction}
        setActiveTab={setActiveTab}
      />
    ),
  };

  return renderTab[activeTab];
};

const mapStateToProps = (store) => {
  const { application } = store;

  return {
    userInfo: application.userInfo,
    companies: application.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompaniesService: () => dispatch(getCompanies()),
    getUserInfoAction: () => dispatch(getUserInfo()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileModeration));
