const ROUTE_HOME = '/';
const ROUTE_UNAUTHORIZED = '/sign-in';
const ROUTE_MODERATION = '/profile';
const ROUTE_GAS_B2C = '/gas-b2c';
const ROUTE_GAS_B2B = '/gas-b2b';
const ROUTE_ENERGY = '/energiya';
const ROUTE_GAS_B2C_DASHBOARD = '/gas-b2c/dashboard';
const ROUTE_GAS_B2B_DASHBOARD = '/gas-b2b/dashboard';
const ROUTE_ENERGIYA_B2B = '/energiya/dashboard';
const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_DOCUMENTS = '/gas-b2b/documents';
const ROUTE_DOCUMENTS_CONTRACTS = '/gas-b2b/documents/contracts';
const ROUTE_ENERGY_DOCUMENTS = '/energiya/documents';
const ROUTE_ENERGY_DOCUMENTS_CONTRACTS = '/energiya/documents/contracts';
const ROUTE_TECHNICAL_WORK = '/technical-work';
const ROUTES_UNAUTHORIZAED_ALLOWED = [
  '/',
  '/b2b',
  '/b2b/sign-up',
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/b2b-bot/auth',
  '/b2b-bot/documents',
  '/technical-work',
];
const ROUTES_BOT = ['/b2b-bot'];
const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

const REDIRECT_GAS_DASHBOARD = ROUTE_GAS_B2B_DASHBOARD
const REDIRECT_GAS_DOCUMENTS = ROUTE_DOCUMENTS
const REDIRECT_GAS_ORDER = '/gas-b2b/order'
const REDIRECT_GAS_INVOICE = '/gas-b2b/new-invoice'
const REDIRECT_GAS_CONTRACTS = '/gas-b2b/documents/contracts'
const REDIRECT_GAS_SUBCONTRACTS = '/gas-b2b/documents/subcontracts'
const REDIRECT_GAS_INVOICES = '/gas-b2b/documents/invoices'
const REDIRECT_GAS_ACTS = '/gas-b2b/documents/acts'

const REDIRECT_ENERGY_DASHBOARD = ROUTE_ENERGIYA_B2B
const REDIRECT_ENERGY_DOCUMENTS = ROUTE_ENERGY_DOCUMENTS
const REDIRECT_ENERGY_ORDER = '/energiya/order'
const REDIRECT_ENERGY_INVOICE = '/energiya/new-invoice'
const REDIRECT_ENERGY_CONTRACTS = '/energiya/documents/contracts'
const REDIRECT_ENERGY_SUBCONTRACTS = '/energiya/documents/subcontracts'
const REDIRECT_ENERGY_INVOICES = '/energiya/documents/invoices'
const REDIRECT_ENERGY_ACTS = '/energiya/documents/acts'

export {
  ROUTE_HOME,
  ROUTE_UNAUTHORIZED,
  ROUTE_MODERATION,
  ROUTES_UNAUTHORIZAED_ALLOWED,
  ROUTE_GAS_B2B_DASHBOARD,
  ROUTE_ENERGIYA_B2B,
  ROUTE_GAS_B2C,
  ROUTE_GAS_B2B,
  ROUTE_ENERGY,
  ROUTE_GAS_B2C_DASHBOARD,
  EMAIL_REGEXP,
  ROUTES_BOT,
  ROUTE_DASHBOARD,
  ROUTE_DOCUMENTS,
  ROUTE_DOCUMENTS_CONTRACTS,
  ROUTE_ENERGY_DOCUMENTS,
  ROUTE_ENERGY_DOCUMENTS_CONTRACTS,
  ROUTE_TECHNICAL_WORK,
  REDIRECT_GAS_DASHBOARD,
  REDIRECT_GAS_DOCUMENTS,
  REDIRECT_GAS_ORDER,
  REDIRECT_GAS_INVOICE,
  REDIRECT_GAS_CONTRACTS,
  REDIRECT_GAS_SUBCONTRACTS,
  REDIRECT_GAS_INVOICES,
  REDIRECT_GAS_ACTS,
  REDIRECT_ENERGY_DASHBOARD,
  REDIRECT_ENERGY_DOCUMENTS,
  REDIRECT_ENERGY_ORDER,
  REDIRECT_ENERGY_INVOICE,
  REDIRECT_ENERGY_CONTRACTS,
  REDIRECT_ENERGY_SUBCONTRACTS,
  REDIRECT_ENERGY_INVOICES,
  REDIRECT_ENERGY_ACTS
};

export const evenPayment = 'ТВІЙ ГАЗ РІВНОМІРНИЙ ПЛАТІЖ';
