import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormDropdown from '../../../common/FormDropdown/FormDropdown';
import { instanceFetch } from '../../../utils/instanceFetch';
import { middlewareUrl } from '../../../services/config';
import authHeader from '../../../utils/authHeader';
import { Form, Space, Spin, Table, DatePicker } from 'antd';
import moment from 'moment';
import UA from 'antd/lib/locale/uk_UA';
import 'moment/locale/uk';
moment.locale('uk');
const { RangePicker } = DatePicker;

const Requests = ({ userInfo }) => {
  const [form] = Form.useForm();

  const [accountInfo, setAccountInfo] = useState([]);
  const [supplyInfo, setSupplyInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredList, setFilteredList] = useState(accountInfo);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [locale, useLocale] = useState(UA);

  const accountNumberArr = userInfo.accountsList.map((item) => item.account.accountNumber);

  const filterByAccount = (filteredData) => {
    if (!selectedAccount) {
      return filteredData;
    }

    const filteredAccount = filteredData.filter(
      (account) => account.accountNumber === selectedAccount
    );

    return filteredAccount;
  };

  const handleAccountChange = (value) => {
    const accountNumber = value;

    if (accountNumber === selectedAccount) {
      setSelectedAccount('');
    } else {
      setSelectedAccount(accountNumber);
    }
  };

  const filterByProvider = (filteredData) => {
    if (!selectedProvider) {
      return filteredData;
    }
    const filteredProvider = filteredData.filter(
      (provider) => provider.companyName === selectedProvider
    );

    return filteredProvider;
  };

  const handleProviderChange = (value) => {
    const providerValue = value;

    if (providerValue === selectedProvider) {
      setSelectedProvider('');
    } else {
      setSelectedProvider(providerValue);
    }
  };

  const handleDateChange = (dates, dateStrings, filteredData) => {
    /*if (dates) {
    console.log('From: ', dates[0], ', to: ', dates[1]);
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      const filteredDates = filteredData.filter(
        date => dateStrings[0] <=
          new Date(date.date_doc).toLocaleString().slice(0, 10).replaceAll('/', '.') &&
          new Date(date.date_doc).toLocaleString().slice(0, 10).replaceAll('/', '.') <=
          dateStrings[1]
      )
      return filteredDates;
    }*/
    // console.log(filteredData)
    // console.log(filteredDates)
  };

  useEffect(() => {
    accountNumberArr.map((item) => {
      instanceFetch
        .get(`${middlewareUrl}/api/account/${item}/info`, authHeader())
        .then((info) =>
          instanceFetch
            .get(
              `${middlewareUrl}/api/account/${item}/tariffs/requests?date_start=2021-01-01&date_end=${new Date()
                .toJSON()
                .slice(0, 10)}`,
              authHeader()
            )
            .then((tariffs) => {
              if (tariffs.length !== 0) {
                const requestData = Object.assign({}, ...tariffs, info.bank_account, {
                  accountNumber: item,
                });
                const supplyData = Object.assign({}, info.bank_account);
                setAccountInfo((current) => [...current, requestData]);
                setSupplyInfo((current) => [...current, supplyData.companyName]);
              }
            })
        )
        .finally(() => setLoading(false));
    });
  }, [userInfo]);

  useEffect(() => {
    let filteredData = filterByAccount(accountInfo);
    filteredData = filterByProvider(filteredData);
    // filteredData = handleDateChange(filteredData);
    setFilteredList(filteredData);
  }, [accountInfo, selectedAccount, selectedProvider, selectedYear]);

  const resetFilter = () => {
    setFilteredList(accountInfo);
    form.resetFields();
  };

  const columns = [
    {
      title: 'Обліковий рахунок',
      dataIndex: 'account',
      className: 'account',
    },
    {
      title: 'Призначення',
      dataIndex: 'purpose',
      className: 'purpose',
    },
    {
      title: 'Постачальник',
      dataIndex: 'provider',
      className: 'provider',
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      className: 'date',
    },
    {
      title: 'Статус заявки',
      dataIndex: 'request_status',
      className: 'request_status',
    },
  ];
  const requestTableData = filteredList.map(
    ({ accountNumber, full_address, tariff_name, companyName, date_doc, status_varname }) => ({
      account: (
        <div className="request-cell">
          <span className="request-cell__bold">о/р {accountNumber}</span>
          <span className="request-cell">{full_address}</span>
        </div>
      ),
      purpose: (
        <div className="request-cell">
          <span>Зміна тарифного плану</span>
          <span>{tariff_name}</span>
        </div>
      ),
      provider: (
        <div>
          <span>{companyName}</span>
        </div>
      ),
      date: (
        <div>
          <span>{new Date(date_doc).toLocaleString().slice(0, 10).replaceAll('/', '.')}</span>
        </div>
      ),
      request_status: (
        <>
          {status_varname === 'rejected' && (
            <div className="request-cell-status request-cell-status__rejected">
              <img src="/icons/alert-triangle.svg" alt="alert-triangle" />
              <div className="request-cell-status__text">Відхилено</div>
            </div>
          )}
          {status_varname === 'processing' && (
            <div className="request-cell-status request-cell-status__processing">
              <img src="/icons/dots.svg" alt="dots" />
              <div className="request-cell-status__text">В обробці</div>
            </div>
          )}
          {status_varname === 'wait_pay' && (
            <div className="request-cell-status request-cell-status__wait_pay">
              <img src="/icons/dots.svg" alt="dots" />
              <div className="request-cell-status__text">Очікується оплата</div>
            </div>
          )}
          {status_varname === 'executed' && (
            <div className="request-cell-status request-cell-status__executed">
              <img src="/icons/done-circle.svg" alt="done" />
              <div className="request-cell-status__text">Виконано</div>
            </div>
          )}
        </>
      ),
    })
  );

  if (loading)
    return (
      <div className="dashboard-b2c-item loading">
        <Spin />
      </div>
    );

  return (
    <div className="request-b2c">
      <div className="request-b2c__filters">
        <div className="request-b2c__filters-wrap">
          <Form form={form} className="filters-form">
            <FormDropdown
              defaultValue="Обліковий рахунок"
              options={accountNumberArr}
              handleSelectChange={handleAccountChange}
            />
            <FormDropdown
              defaultValue="Постачальник"
              options={supplyInfo}
              handleSelectChange={handleProviderChange}
            />
            {/*<FormDropdown defaultValue="Період" options={dateArr} />*/}
            {/*<Space direction="vertical" size={12}>
              <RangePicker
                locale={locale}
                format='DD.MM.YYYY'
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={handleDateChange}
              />
            </Space>*/}
          </Form>
        </div>
        <button type="button" className="underline-link" onClick={resetFilter}>
          Очистити фільтр
        </button>
      </div>
      <div className="request-b2c__table">
        {!loading && (
          <div className="custom-ant-table">
            <Table bordered columns={columns} dataSource={requestTableData} pagination={false} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(Requests);
