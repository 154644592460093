import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import renderErrors from '../../helper/functions';
import Button from '../../common/Button/Button';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import InputText from '../../common/Inputs/InputText';
import { registerAccountAddress } from '../../store/actions/register';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserInfo } from '../../store/actions/userInfo';
import { instanceFetch } from '../../utils/instanceFetch';
import authHeader from '../../utils/authHeader';
import { middlewareUrl } from '../../services/config';

const AddProfileWidget = ({ widgetHeader, widgetSubheader, widgetButton, getUserInfoAction }) => {
  const [form] = Form.useForm();

  const [error, setError] = useState({
    message: null,
    messages: null,
  });

  const [isVisiblePopup, setVisiblePopup] = useState(false);
  const [accountNumber, setAccountNumber] = useState();
  const [lastPayment, setLastPayment] = useState();

  const handleModalVisible = () => {
    setVisiblePopup(!isVisiblePopup);
  };

  const ROUTES = {
    ACCOUNT_STORE: new URL(`${middlewareUrl}/api/account/store`),
  };

  const handlerSubmit = () => {
    instanceFetch
      .post(
        ROUTES.ACCOUNT_STORE,
        {
          accountNumber: accountNumber,
          last_payment_sum: lastPayment,
          main_meter_no: '1',
        },
        authHeader()
      )
      .then(() => {
        getUserInfoAction();
      })
      .catch((error) => {
        error.errors
          ? setError({
              messages: error.errors,
              message: null,
            })
          : setError({
              message: error.message,
              messages: null,
            });
      });
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setAccountNumber(value);
    form.setFieldsValue({
      accountNumber: value,
    });
  };

  const handleLastPaymentChange = (e) => {
    const value = e.target.value
      .replace(',', '.')
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\.\d{2}).+/g, '$1');
    setLastPayment(value);
    form.setFieldsValue({
      last_payment_sum: value,
    });
  };

  useEffect(() => {
    renderErrors(form, error.messages);
  }, [error.messages]);

  return (
    <div>
      <div className="empty-profile-widget">
        <div className="empty-profile-widget__wrap">
          <div className="empty-profile-widget__header">{widgetHeader}</div>
          <div className="empty-profile-widget__subheader">{widgetSubheader}</div>
        </div>
        <div className="empty-profile-widget__btn">
          <Button type="button" className="btn" onClick={() => setVisiblePopup(!isVisiblePopup)}>
            <img src="/icons/plus-black.svg" className="btn__icon-left" alt="plus-icon" />
            <span>{widgetButton}</span>
          </Button>
        </div>
      </div>

      {isVisiblePopup && (
        <ModalWrapper
          className="add-profile-form"
          isModalVisible={isVisiblePopup}
          handleClick={handleModalVisible}
          closeIcon={<img src="/icons/modal-close-black.svg" />}
        >
          <div className="add-profile-form">
            <div className="add-profile-form__title">Додати новий обліковий рахунок</div>
            <div className="add-profile-form__subtitle">Введіть дані облікового рахунку</div>
            <Form name="personal-account" onFinish={handlerSubmit} form={form}>
              <InputText
                name="accountNumber"
                labelText="Введіть номер Особового рахунку"
                tooltipText="Введіть номер Особового рахунку"
                rules={[{ required: true, message: 'Будь ласка введіть номер Особового рахунку' }]}
                onChange={handleAccountNumberChange}
                value={accountNumber}
              />

              <InputText
                name="last_payment_sum"
                labelText="Введіть суму останньої транзакції, грн"
                tooltipText="Введіть суму останньої транзакції, грн"
                onChange={handleLastPaymentChange}
                rules={[
                  { required: true, message: 'Будь ласка введіть суму останньої транзакції, грн' },
                ]}
                value={lastPayment}
                getValueFromEvent={handleLastPaymentChange}
                getValueProps={lastPayment}
              />
              <Button type="primary" typeHtml="submit" text="Знайти адресу" />
              {error.message && <div className="error">{error.message}</div>}
            </Form>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
    companies: application.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerNewAccount: (payload) => dispatch(registerAccountAddress(payload)),
    getUserInfoAction: () => dispatch(getUserInfo()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProfileWidget));
