import React from 'react';
import Button from '../../../common/Button/Button';
import { Link } from 'react-router-dom';

const ContentStep1 = () => {
  return (
    <div className="registration-content">
      <span className="registration-content__title">Кабінет споживачів газу</span>
      <span className="registration-content__sub-title">
        Зареєструйтесь або увійдіть, щоб відчути всі переваги особистого кабінету
      </span>
      <div className="reg-content-buttons">
        <Link to="/sign-up">
          <Button type="primary" text="Зареєструватись" />
        </Link>
        <Link to="/sign-in">
          <Button type="outline-primary" text="Увійти" />
        </Link>
      </div>
    </div>
  );
};

export default ContentStep1;
