import React, { useState } from 'react';
import { connect } from 'react-redux';
import ProfileModeration from './ProfileModeration';
import UserInfo from './UserInfo';
import UpdateUserContacts from './UpdateUserContacts';

const MyProfileTab = ({ userInfo }) => {
  const [renderContent, setRenderContent] = useState('userInfo');

  if (!userInfo.gasB2c) return <ProfileModeration />;

  const contentList = {
    userInfo: <UserInfo setRenderContent={setRenderContent} />,
    email: <UpdateUserContacts loginTypeName="email" setRenderContent={setRenderContent} />,
    phone: <UpdateUserContacts loginTypeName="phone" setRenderContent={setRenderContent} />,
  };

  return <div className="profile__block">{contentList[renderContent]}</div>;
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(MyProfileTab);
