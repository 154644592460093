import React, { useState, useMemo } from 'react';
import DashboardContract from './DashboardContract';
import DashboardGeneralInfo from './DashboardGeneralInfo';
import { useGasConsumerDocument } from './useGasConsumerDocument';
import { Spin } from 'antd';
import EmptyData from '../../components/EmptyData/EmptyData';

const DashboardGas = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { documentsArr, loader, dataIsEmpty } = useGasConsumerDocument();

  const agreementsFilterValues = [7, 39, 40, 41, 42, 62, 63, 64];
  const filteredAgreements = documentsArr.filter(
    (val) => agreementsFilterValues.includes(val.contract_type) && val.is_contract_active === 1
  );

  // console.log('documentsArr', documentsArr)
  // console.log('filteredAgreements', filteredAgreements)

  const arrDashboardTab = [
    {
      label: 'Загальна інформація',
      content: () => <DashboardGeneralInfo />,
    },
    {
      label: `Договори (${filteredAgreements.length})`,
      content: () => <DashboardContracts />,
    },
  ];

  console.log(documentsArr);

  const DashboardContracts = () => {
    return (
      <div className="dashboard__contracts">
        <DashboardContract filteredAgreements={documentsArr} />
      </div>
    );
  };

  const Content = useMemo(() => {
    return arrDashboardTab[activeTab].content;
  }, [activeTab, arrDashboardTab]);

  return (
    <div className="dashboard dashboard_gas">
      <div className="dashboard__contracts">
        {loader ? (
          <div className="dashboard__loader">
            <Spin />
          </div>
        ) : (
          documentsArr.map((item, key) => <DashboardContract key={key} item={item} />)
        )}
        {dataIsEmpty && <EmptyData />}
      </div>
    </div>
  );
};

export default DashboardGas;
