import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddProfileWidget from '../../../components/AddProfileWidget/AddProfileWidget';
import AddressItem from './AddressItem';

const Address = ({ userInfo }) => {
  return (
    <div className="dashboard-b2c">
      {userInfo.accountsList.map((item, index) => (
        <AddressItem key={index} item={item} />
      ))}

      {userInfo.gasB2c && (
        <div className="dashboard-add-account">
          <AddProfileWidget
            widgetHeader="Мої адреси та доступ до них"
            widgetSubheader="Додавайте інші облікові рахунки, за які ви хотіли б сплачувати через особистий кабінет"
            widgetButton="Додати адресу"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;
  return {
    userInfo: application.userInfo,
  };
};

export default connect(mapStateToProps)(Address);
